<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="d-flex align-items-start justify-content-around">
      <div style="width: 25%; height:70vh;" class="card" v-if="assessment" >
        <div
          class="
            card-header
            d-flex
            flex-row
            align-items-start
            justify-content-between
          "
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h2 class="mb-25 font-weight-bolder">{{ assessment.title }}</h2>
            <p class="font-weight-bold">{{ assessment.description }}</p>
          </div>
        </div>
        <div class="card-body">
          <div
            class="d-flex flex-column align-items-center justify-content-end h-100"
          >
            <donut-bar
              :percentage="Number(assessment.completion.toFixed(2))"
              :displayValue="assessment.completion + '%'"
              size="sm"
              :uniqueId="assessment._id + 2"
              :colorsReversed="true"
            />
            <p class="font-weight-bold" style="font-size:12px;">Overall Progress</p>
          </div>
        </div>
      </div>

      <div style="width: 70%; height:70vh;" class="card" v-if="assessment !== null">
        <div class="card-header pb-0">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h2 class="mb-1">
              Vendors
              <b-badge pill variant="info" size="sm"
                ><span class="font-weight-bolder">{{
                  assessment.groupedQuestionnaires.length
                }}</span></b-badge
              >
            </h2>
          </div>
        </div>
        <div
          class="card-body bg-light-secondary py-25 px-50"
          style="height: 55vh"
        >
          <vue-perfect-scrollbar
            :settings="{ maxScrollbarLength: 60 }"
           class="h-100 w-100"
          >
            <vendor-questionnaire-card
              v-for="vendorQuestionnaire in assessment.groupedQuestionnaires"
              :key="vendorQuestionnaire.vendor._id"
              :vendorQuestionnaire="vendorQuestionnaire"
            />
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BSidebar, BButton, BAvatar, BBadge } from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import VendorQuestionnaireCard from "./components/VendorQuestionnairesCard.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    VendorQuestionnaireCard,
    BBadge,
    VuePerfectScrollbar,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      assessment: null,
      filters: {
        page: 1,
      },
      pagination: {
        perPage: 10,
        lastPage: 1,
      },
      isUserVendor: false,
    };
  },

  mixins: [
    ThirdPartyRisksMixins,
    ThirdPartyRisksRAMixins,
    QuestionnaireMixins,
    ResponseMixins,
    UtilsMixins,
    TaskMixins,
  ],

  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getAssessmentDetailsAndSetData(this.$route.params.assessmentId);
    },

    handleQuestionnaireClick(questionnaireId) {
      //   this.$router.push(
      //     `/thirdpartyrisks/${this.$route.params.id}/details/assessment/${this.$route.params.assessmentId}/questionnaire/${questionnaireId}`
      //   );
    },

    getAssessmentDetailsAndSetData(id) {
      this.getAssessmentDetails(id)
        .then((res) => {
          this.assessment = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getVendorDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getVendorDetails(id)
        .then((res) => {
          this.vendor = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({ dont_paginate: true }, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
    },
    openAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>