<template>
  <div
    class="
      bg-light
      rounded
      shadow
      d-flex
      flex-column
      align-items-start
      justify-content-start
      my-50
      px-1
      w-100
    "
    style="position: relative"
    @click="handleDropdownClick"
  >
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        w-100
        cursor-pointer
        py-50
      "
    >
      <div
        style="width: 30%"
        class="d-flex flex-column align-items-start justify-content-start"
      >
        <p class="font-weight-bold mb-0" style="font-size: 12px">Name</p>
        <h4
          class="mb-0 font-weight-bolder text-truncate"
          v-b-tooltip.hover.top.v-secondary
          :title="vendorQuestionnaire.vendor.name"
        >
          {{ vendorQuestionnaire.vendor.name }}
        </h4>
      </div>

      <div
        class="d-flex align-items-center justify-content-end"
        style="width: 60%"
      >
        <div
          class="
            d-flex
            align-items-center
            justify-content-start
            bg-light
            p-50
            rounded
            mr-3
          "
        >
          <p class="mb-0 font-weight-bold mr-1">
            Questionnaires:<b-badge pill variant="info" class="ml-50">{{
              vendorQuestionnaire.questionnaires.length.toString()
            }}</b-badge>
          </p>
        </div>

        <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            mr-3
          "
          v-if="
            vendorQuestionnaire.vendor.vendor_category &&
            vendorQuestionnaire.vendor.vendor_category !== null
          "
        >
          <p style="font-size: 12px" class="mb-25 font-weight-bold">Category</p>
          <b-badge
            style="max-width: 120px"
            variant="primary"
            class="text-truncate"
            v-b-tooltip.hover.top.v-info
            :title="vendorQuestionnaire.vendor.vendor_category"
            >vendorQuestionnaire.vendor.vendor_category</b-badge
          >
        </div>

        <progress-bar
          :value="Number(vendorQuestionnaire.completion)"
          :min="1"
          :max="100"
          title="Progress:"
        />
      </div>

      <div
        style="width: auto"
        class="d-flex align-items-center justify-content-end"
      >
        <div class="d-flex align-items-center justify-content-center">
          <b-button
            @click.stop="handleDropdownClick"
            variant="flat-dark"
            class="btn-icon"
          >
            <feather-icon
              :style="{ transform: `rotate(${iconRotateAngle}deg)` }"
              class="transition"
              icon="ChevronDownIcon"
              size="28"
            />
          </b-button>
        </div>
      </div>
    </div>

    <transition name="expand" mode="out-in" style="position: relative">
      <div
        class="
          mt-1
          d-flex
          flex-column
          align-items-start
          justify-content-start
          w-100
        "
        style="position: relative"
        v-if="isDropped == true"
      >
        <div class="card w-100">
          <div class="card-header">
            <p class="card-title">
              Questionnaires
              <!-- <b-badge pill variant="info" size="sm"
                ><span class="font-weight-bolder">{{
                  vendorQuestionnaire.questionnaires.length
                }}</span></b-badge
              > -->
            </p>
          </div>
          <div class="card-body">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col" class="text-center">
                    Questions
                  </th>
                  <th role="columnheader" scope="col" class="text-center">
                    Gap
                  </th>
                  <th role="columnheader" scope="col" class="text-center">
                    Progress
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="questionnaire in vendorQuestionnaire.questionnaires"
                  :key="questionnaire._id"
                  role="row"
                  class="cursor-pointer"
                  @click.stop="handleQuestionnaireClick(questionnaire._id)"
                >
                  <td role="cell" style="padding-right: 0; max-width: 140px">
                    <div
                      class="d-flex justify-content-start"
                      style="max-width: 140px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="questionnaire.title"
                    >
                      <p
                        class="
                          text-left text-truncate
                          font-weight-bolder
                          mb-0
                        "
                      >
                        {{ questionnaire.title }}
                      </p>
                    </div>
                  </td>

                  <td role="cell" style="padding-right: 0; max-width: 140px">
                    <div
                      class="d-flex justify-content-start"
                      style="max-width: 140px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="questionnaire.description"
                    >
                      <p
                        class="
                          text-left text-truncate
                          font-italic font-weight-bold
                          mb-0
                        "
                      >
                        {{ questionnaire.description }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div class="d-flex justify-content-center">
                      <b-avatar
                        :text="questionnaire.total_questions.toString()"
                        variant="light-primary"
                      />
                    </div>
                  </td>

                  <td role="cell">
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        m-0
                      "
                    
                    >
                      <!-- <donut-bar
                        :percentage="Number(questionnaire.gap.toFixed(2))"
                        :displayValue="questionnaire.gap + '%'"
                        size="xsm"
                        :uniqueId="questionnaire._id + 1"
                      /> -->
                       <progress-badge
                          :max="100"
                          :min="0"
                          :value="Number(questionnaire.gap.toFixed(1))"
                          title="Progress"
                        />
                    </div>
                  </td>
                  <td role="cell">
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        m-0
                      "
                     
                    >
                      <!-- <donut-bar
                        :percentage="
                          Number(questionnaire.completion.toFixed(2))
                        "
                        :displayValue="questionnaire.completion + '%'"
                        size="xsm"
                        :uniqueId="questionnaire._id + 2"
                        :colorsReversed="true"
                      /> -->
                        <progress-badge
                          :max="100"
                          :min="0"
                          :value="Number(questionnaire.completion.toFixed(1))"
                          title="Progress"
                        />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DonutBar from "../../../../components/DonutBar.vue";
import ProgressBar from "../../../../components/ProgressBar2.vue";
import ProgressBadge from "../../../../components/ProgressBadge.vue";
import Ripple from "vue-ripple-directive";
import { BAvatar, BButton, VBTooltip, BBadge } from "bootstrap-vue";

export default {
  components: { DonutBar, BAvatar, BButton, ProgressBar, BBadge, ProgressBadge },
  directives: { "b-tooltip": VBTooltip, Ripple },
  props: {
    vendorQuestionnaire: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isDropped: false,
      iconRotateAngle: 0,
    };
  },

  watch: {
    isDropped(newValue) {
      if (newValue == true) {
        this.iconRotateAngle = 180;
      } else {
        this.iconRotateAngle = 0;
      }
    },
  },
  methods: {
    handleDropdownClick() {
      this.isDropped = !this.isDropped;
    },

    handleQuestionnaireClick(questionnaireId) {
      this.$router.push(
        `/thirdpartyrisksRA/assessments/${this.$route.params.assessmentId}/questionnaire/${questionnaireId}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.transition {
  transition: transform 0.5s ease-in-out;
}

.expand-enter-active {
  transition: all 0.3s ease;
}
.expand-leave-active {
  transition: all 0.3s ease;
}
.expand-enter, .expand-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-50px);
  opacity: 0;
}
</style>