<template>
  <div style="width: 120px">
    <div class="d-flex justify-content-start align-items-center mb-50 px-25">
      <p class="font-weight-bolder mb-0">
        {{ title }}
      </p>
         <b-badge
          pill
          :variant="getVariant(Math.floor(getMapedValue(value, 1, max, 1, 5)))"
          class="p-25 ml-25"
          >{{ value }}%</b-badge
        >
    </div>
    <b-progress
      :variant="getVariant(Math.floor(getMapedValue(value, 1, max, 1, 5)))"
      :value="value"
      :max="max"
      striped
      animated
    />
  </div>
</template>

<script>
import { BProgress, VBTooltip, BBadge } from "bootstrap-vue";

export default {
  components: {
    BProgress,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    colorsReversed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
       getVariant(status) {
      switch (status) {
        case 1:
          return this.colorsReversed ? "danger" : "success";
        case 2:
          return this.colorsReversed ? "warning" : "info";
        case 3:
          return "primary";
        case 4:
          return this.colorsReversed ? "info" : "warning";
        case 5:
          return this.colorsReversed ? "success" : "danger";
        default:
          return "secondary";
      }
    },
    getLightVariant(status) {
      switch (status) {
        case 1:
          return this.colorsReversed ? "light-danger" : "light-success";
        case 2:
          return this.colorsReversed ? "light-warning" : "light-info";
        case 3:
          return "light-primary";
        case 4:
          return this.colorsReversed ? "light-info" : "light-warning";
        case 5:
          return this.colorsReversed ? "light-success" : "light-danger";
        default:
          return "light-secondary";
      }
    },
    getBGLightVariant(status) {
      switch (status) {
        case 1:
          return this.colorsReversed ? "bg-light-danger" : "bg-light-success";
        case 2:
          return this.colorsReversed ? "bg-light-warning" : "bg-light-info";
        case 3:
          return "bg-light-primary";
        case 4:
          return this.colorsReversed ? "bg-light-info" : "bg-light-warning";
        case 5:
          return this.colorsReversed ? "bg-light-success" : "bg-light-danger";
        default:
          return "bg-light-secondary";
      }
    },
    getTextVariant(status) {
      switch (status) {
        case 1:
          return this.colorsReversed ? "text-danger" : "text-success";
        case 2:
          return this.colorsReversed ? "text-warning" : "text-info";
        case 3:
          return "text-primary";
        case 4:
          return this.colorsReversed ? "text-info" : "text-warning";
        case 5:
          return this.colorsReversed ? "text-success" : "text-danger";
        default:
          return "text-secondary";
      }
    },
    getMapedValue(value, in_min, in_max, out_min, out_max) {
      const newValue =
        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
      return newValue;
    },
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
  },
};
</script>

<style>
</style>